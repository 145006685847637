/* eslint-disable max-len */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/reduxHooks";
import { Button, Card, Col, Form, Input, Image, Typography, Row } from "antd";
import { doLogin } from "store/slices/auth.slice";
import { notificationController } from "components/Common/Notification/Notification";
import { LoginRequest } from "api/auth.api";


export const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values: LoginRequest) => {
    setLoading(true);
    dispatch(doLogin(values))
      .unwrap()
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card bordered={true} style={{ width: 400 }} className="shadow">
          <Row justify={"center"}>
            <Col>
              <Image
                className="login-card"
                src="https://res.cloudinary.com/verifiit/image/upload/v1624773443/logo-verfiit_pfaxvr.png"
                preview={false}
              />
            </Col>
          </Row>
          <Row justify={"center"}>
            <Col>
              <Typography.Title level={3}>Login</Typography.Title>{" "}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
                autoComplete="off"
              >
                <Form.Item
                  label="Username"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};
