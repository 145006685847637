/* eslint-disable max-len */
/* eslint-disable newline-per-chained-call */
import React from "react";

import { Dropdown, Layout, Menu, MenuProps, Space, theme } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { Outlet, useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks/reduxHooks";


const { Header, Content, Footer } = Layout;

const MainLayout: React.FC = () => {
  const navigate = useNavigate();
  const user = useAppSelector(state => state.auth.userDetails);

  const {
    token: { borderRadiusLG, colorBgContainer },
  } = theme.useToken();

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a  onClick={()=>{
          navigate("/profile");
        }}>
          Profile
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a onClick={()=>{
          navigate("/logout");
        }}>
         Logout
        </a>
      ),
      // icon: <SmileOutlined />,
      // disabled: true,
    },
   
  ];

  return (
    <Layout>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="demo-logo">
          <img
            src="https://res.cloudinary.com/verifiit/image/upload/v1624773443/logo-verfiit_pfaxvr.png"
            alt=""
          />{" "}
        </div>
        <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>  <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["dashboard"]}
          items={[
            {
              key: "dashboard",
              label: "Searches",
              onClick: () => {
                navigate("/dashboard");
              },
            },
            {
              key: "add-record",
              label: "Search New Data",
              onClick: () => {
                navigate("/add-record");
              },
            },
          ]}
        
        />
        <Dropdown menu={{ items }}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
       Welcome {user?.firstName + " "+user?.lastName}
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
        </div>
      </Header>
      <Content style={{ padding: "0 48px" }}>
        <div
          style={{
            padding: 24,
            minHeight: 380,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Outlet />
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Verifiit {new Date().getFullYear()} 
      </Footer>
    </Layout>
  );
};

export default MainLayout;
