export const themeSetting = {
  token: {
    colorPrimary: "#00b96b",
  },
  components: {
    Radio: {
      colorPrimary: "#00b96b",
    },
  },
};
        
